/* eslint-disable react/jsx-props-no-spreading */
import Input from 'components/common/input/Input'
import { ErrorMessage, Field, useFormikContext } from 'formik'
import { useDropzone } from 'react-dropzone'
import React, { useState } from 'react'
import { EVENT_TYPE } from 'constants/Events'
// import Spinner from 'react-bootstrap/Spinner'
import ProgressBar from 'react-bootstrap/ProgressBar'
import SelectComponent from 'components/common/select/SelectComponent'

// import React, { useCallback } from 'react'
import CustomDatePicker from 'components/common/custom-datepicker/CustomDatepicker'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { CloudArrowUp } from 'phosphor-react'
import MapViewWrapper from 'components/common/map-view/MapView'
import { minioSingleFileUpload } from 'containers/events/Api'
import { toast } from 'react-toastify'
import CustomToast from 'components/common/custom-toast/CustomToast'
import { alertTypes } from 'constants/Common'
import Delete from '../../../assets/images/delete.png'

function FirstStep() {
  const navigate = useNavigate()
  const formik = useFormikContext()
  const [fileLoading, setFileLoading] = useState(false)
  const [ContentFileLoading, setContentFileLoading] = useState(false)
  const [preWorkFileLoading, setPreWorkFileLoading] = useState(false)

  const [uploadProgress, setUploadProgress] = useState(0)
  const [contentUploadProgress, setContentUploadProgress] = useState(0)
  const [preUploadProgress, setPreUploadProgress] = useState(0)

  const handleFileUpload = async (file, contentType) => {
    const formData = new FormData()
    formData.append('file', file, 'file')
    formData.append('project', 'xler')
    formData.append('fileType', file.type)

    console.log('file', file)

    const onProgress = (percent) => {
      if (contentType === 'photo') {
        setFileLoading(true)
        setUploadProgress(percent)
      } else if (contentType === 'content') {
        setContentFileLoading(true)
        setContentUploadProgress(percent)
      } else if (contentType === 'preWork') {
        setPreWorkFileLoading(true)
        setPreUploadProgress(percent)
      }
    }

    const result = await minioSingleFileUpload(formData, onProgress)
    console.log(result)
    if (result?.message) {
      if (result?.paths)
        formik.setFieldValue(contentType, [
          ...(formik.values[contentType] || []),
          { link: result.paths, name: file.name },
        ])

      setFileLoading(false)
      setContentFileLoading(false)
      setPreWorkFileLoading(false)
      setUploadProgress(0)
      setContentUploadProgress(0)
      setPreUploadProgress(0)
      toast(
        <CustomToast
          variant={alertTypes.SUCCESS}
          message={result?.message || 'Successfully!'}
        />
      )
    } else {
      setFileLoading(false)
      setContentFileLoading(false)
      setPreWorkFileLoading(false)
      setUploadProgress(0)
      setContentUploadProgress(0)
      setPreUploadProgress(0)
      toast(
        <CustomToast
          variant={alertTypes.DANGER}
          message={result?.response?.data?.error}
        />
      )
    }
  }

  const createOnDrop = (contentType) => (files) => {
    if (files.length > 0) {
      const uploadedFiles = Array.from(files)
      uploadedFiles.forEach((file) => handleFileUpload(file, contentType))
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: createOnDrop('photo'),
    accept: 'image/svg+xml, image/png, image/jpeg, image/gif, video/*',
    // maxSize: 800 * 400,
    maxSize: 50 * 1024 * 1024,
    onDropRejected: (fileRejections) => {
      fileRejections.forEach((rejection) => {
        rejection.errors.forEach((error) => {
          if (error.code === 'file-too-large') {
            toast(
              <CustomToast
                variant={alertTypes.DANGER}
                message={`File too large: ${rejection.file.name} please upload file size less than 50mb`}
              />
            )
          }
        })
      })
    },
  })

  const { getRootProps: getRootProps2, getInputProps: getInputProps2 } =
    useDropzone({
      onDrop: createOnDrop('content'),
      accept: {
        'application/pdf': [], // PDF files
        'application/vnd.ms-powerpoint': [], // PPT files
        'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          [], // PPTX files
      },
      maxSize: 50 * 1024 * 1024,
      onDropRejected: (fileRejections) => {
        fileRejections.forEach((rejection) => {
          rejection.errors.forEach((error) => {
            if (error.code === 'file-too-large') {
              toast(
                <CustomToast
                  variant={alertTypes.DANGER}
                  message={`File too large: ${rejection.file.name} please upload file size less than 50mb`}
                />
              )
            } else if (error.code === 'file-invalid-type') {
              toast(
                <CustomToast
                  variant={alertTypes.DANGER}
                  message={`Invalid file type: ${rejection.file.name} only PDF,PPT,PPTX formats allowed`}
                />
              )
            }
          })
        })
      },
    })

  const { getRootProps: getRootProps3, getInputProps: getInputProps3 } =
    useDropzone({
      onDrop: createOnDrop('preWork'),
      accept: {
        'application/pdf': [], // PDF files
        'application/vnd.ms-powerpoint': [], // PPT files
        'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          [], // PPTX files
      },
      maxSize: 50 * 1024 * 1024,
      onDropRejected: (fileRejections) => {
        fileRejections.forEach((rejection) => {
          rejection.errors.forEach((error) => {
            if (error.code === 'file-too-large') {
              toast(
                <CustomToast
                  variant={alertTypes.DANGER}
                  message={`File too large: ${rejection.file.name} please upload file size less than 50mb`}
                />
              )
            } else if (error.code === 'file-invalid-type') {
              toast(
                <CustomToast
                  variant={alertTypes.DANGER}
                  message={`Invalid file type: ${rejection.file.name} only PDF,PPT,PPTX formats allowed`}
                />
              )
            }
          })
        })
      },
    })
  const handleCancel = () => {
    navigate('/events')
  }

  const removeFile = (file, type) => {
    formik.setFieldValue(
      type,
      formik.values[type].filter((item) => item.name !== file.name)
    )
    console.log(formik.values.preWork)
  }
  console.log('formik errors ', formik.errors)
  return (
    <div className='row'>
      <div className='col-md-6'>
        <Input
          value={formik.values.name}
          name='name'
          handleChange={formik.handleChange}
          placeholder='Event Name'
          label='Event Name'
        />
        <ErrorMessage className='error-text' component='p' name='name' />
      </div>
      <div className='col-md-1' />
      <div className='col-md-4'>
        {fileLoading ? (
          <div className='image-upload flex-column'>
            <p className='ms-4'>Please wait for the File to upload!</p>
            <ProgressBar
              className='w-75'
              now={uploadProgress}
              label={`${uploadProgress}%`}
            />
          </div>
        ) : (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className='image-upload shadow-sm'>
              <CloudArrowUp size={32} />
              <p>
                Clicks to upload or drag and drop <br />
                <span>SVG, PNG, JPG, VIDEO or GIF (max. 50mb)</span>
              </p>
            </div>
          </div>
        )}
        {/* <ErrorMessage className='error-text' component='p' name='photo' /> */}
        <p className='error-text'>{formik.errors?.photo}</p>
        <ul>
          {formik.values?.photo?.map((file) => (
            <li key={file.link}>
              {file.name}{' '}
              <button
                onClick={() => removeFile(file, 'photo')}
                type='button'
                className='btn'
              >
                <img width={20} src={Delete} alt='delete' />
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className='col-md-12'>
        <Input
          name='subHeading'
          value={formik.values.subHeading}
          handleChange={formik.handleChange}
          placeholder='Event Sub Heading'
          label='Event Sub Heading'
        />
        <ErrorMessage className='error-text' component='p' name='subHeading' />
      </div>
      <div className='col-md-12'>
        <Input
          name='description'
          value={formik.values.description}
          handleChange={formik.handleChange}
          placeholder='Event Description'
          label='Event Description'
        />
        <ErrorMessage className='error-text' component='p' name='description' />
      </div>

      <div className='row'>
        <div className='col-md-4 mt-3'>
          <p className='fw-bold text-secondary'>Content</p>

          {ContentFileLoading ? (
            <div className='file-upload shadow-sm p-4 rounded-5 flex-column'>
              <p className='ms-4'>Please wait for the File to upload!</p>
              <ProgressBar
                className='w-75 ms-4'
                now={contentUploadProgress}
                label={`${contentUploadProgress}%`}
              />
            </div>
          ) : (
            <div
              {...getRootProps2()}
              style={{ width: '400px' }}
              className='file-upload shadow-sm p-3 rounded-5'
            >
              <input {...getInputProps2()} />
              <CloudArrowUp size={32} />
              <p>
                Click to upload
                <br />
                <span>PDF&apos;s, PPT&apos;s (max. 50mb)</span>
              </p>
            </div>
          )}

          <ul className='mt-4'>
            {formik.values?.content?.map((file) => (
              <li key={file} className='flex-column'>
                File:{' '}
                <a download style={{ display: 'inline' }} href={file.link}>
                  {file.name}
                </a>
                <button
                  onClick={() => removeFile(file, 'content')}
                  type='button'
                  className='btn'
                >
                  <img width={20} src={Delete} alt='delete' />
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div className='col-md-4 mt-3' style={{ marginLeft: '80px' }}>
          <p className='fw-bold text-secondary'>Pre work</p>

          {preWorkFileLoading ? (
            <div className='file-upload shadow-sm p-4 rounded-5 flex-column'>
              <p className='ms-4'>Please wait for the File to upload!</p>
              <ProgressBar
                className='w-75 ms-4'
                now={preUploadProgress}
                label={`${preUploadProgress}%`}
              />
            </div>
          ) : (
            <div
              {...getRootProps3()}
              style={{ width: '400px' }}
              className='file-upload shadow-sm p-3 rounded-5'
            >
              <input {...getInputProps3()} />
              <CloudArrowUp size={32} />
              <p>
                Click to upload
                <br />
                <span>PDF&apos;s, PPT&apos;s (max. 50mb)</span>
              </p>
            </div>
          )}

          <ul className='mt-4'>
            {formik.values?.preWork?.map((file) => (
              <li key={file}>
                File:{' '}
                <a
                  style={{ display: 'inline' }}
                  download={file.link}
                  href={file.link}
                >
                  {file.name}
                </a>
                <button
                  // onClick={() => removeFile(file, 'preWork')}
                  onClick={() => removeFile(file, 'preWork')}
                  type='button'
                  className='btn'
                >
                  <img width={20} src={Delete} alt='delete' />
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className='mt-4'>
        <p>Select Event Type</p>
        <SelectComponent
          name='type'
          options={EVENT_TYPE}
          selectedValue={formik.values.type}
          placeholder='Select event type'
          handleChange={(obj) => {
            console.log(obj.value)
            formik.setFieldValue('type', obj.value)
          }}
        />
      </div>

      <div className='row'>
        <div className='col-md-12 mt-5'>
          <p className='heading-lg'>Location & Time</p>
        </div>

        <div className='col-md-4'>
          <CustomDatePicker
            name='startDate'
            label='Date From'
            selected={
              formik.values.startDate
                ? new Date(moment(formik.values.startDate))
                : ''
            }
            onDateChange={(date) => formik.setFieldValue('startDate', date)}
          />
          <ErrorMessage className='error-text' component='p' name='startDate' />
        </div>
        <div className='col-md-4'>
          <CustomDatePicker
            name='endDate'
            label='Date To'
            selected={
              formik.values.endDate
                ? new Date(moment(formik.values.endDate))
                : ''
            }
            onDateChange={(date) => formik.setFieldValue('endDate', date)}
          />
          <ErrorMessage className='error-text' component='p' name='endDate' />
        </div>
        {formik.values.type === 'onsite' ? (
          <>
            <div className='col-md-4'>
              <Input
                name='location.address'
                value={formik.values.location?.address}
                handleChange={formik.handleChange}
                placeholder='Address'
                label='Address'
              />
              <ErrorMessage
                className='error-text'
                component='p'
                name='location.address'
              />
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '4px',
              }}
            >
              <Field
                name='separatedByDays'
                type='checkbox'
                style={{ marginRight: '6px', height: '26px' }}
                checked={formik.values.separatedByDays}
              />
              <p>Events separated by days</p>
            </div>

            <div className='col-md-12'>
              <MapViewWrapper
                value={formik.values.location?.mapUrl || ''}
                name='location.mapUrl'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label='Location'
                placeholder='Google Map'
                formik={formik}
              />
              <ErrorMessage
                className='error-text'
                component='p'
                name='location.mapUrl'
              />
            </div>
          </>
        ) : (
          <div className='col-md-4'>
            <Input
              name='webLink'
              value={formik.values.webLink}
              handleChange={formik.handleChange}
              placeholder='Webinar Link'
              label='Webinar Link'
            />
            <ErrorMessage
              className='error-text'
              component='p'
              name='webLink' // Corrected the field name here to match 'webLink'
            />
          </div>
        )}
      </div>

      <div className='col-md-12 d-flex justify-content-end gap-4 mt-5 mb-4'>
        <button
          type='button'
          className='secondary-btn record-btn'
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button type='submit' className='primary-btn record-btn'>
          Next
        </button>
      </div>
    </div>
  )
}

export default FirstStep
